<template>
  <v-container fluid class="pt-0">
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="showDialogEliminarVenta" max-width="320">
      <v-card>
        <v-card-title class="headline">Eliminar Venta?</v-card-title>

        <v-card-text>
          <p>Estás seguro? Se eliminará la venta y los productos serán regresados al encargado.</p>
          <p class="font-weight-bold">
            Vendedor:
            <span class="font-weight-light">{{eliminarVentaItem.nombreVendedor}}</span>
          </p>
          <p class="font-weight-bold">
            Cliente:
            <span class="font-weight-light">{{eliminarVentaItem.nombreCliente}}</span>
          </p>
          <p class="font-weight-bold">
            Tipo de Venta:
            <span class="font-weight-light">{{eliminarVentaItem.tipo}}</span>
          </p>
          <p class="font-weight-bold" v-if="eliminarVentaItem.costoVentaTotal">
            Monto de Venta:
            <span
              class="font-weight-light"
            >S/ {{eliminarVentaItem.costoVentaTotal.toFixed(2)}}</span>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="green darken-1" text @click="showDialogEliminarVenta = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eliminarVenta">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card>
              <v-card-title>
                <div class="d-flex">
                  <div>
                    <p class="my-auto">Información de las Ventas</p>
                  </div>
                  <div>
                    <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="d-flex">
                  <v-dialog
                    ref="dialogFechaTabla"
                    v-model="modalFechaTabla"
                    :return-value.sync="fechaTabla"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateada"
                        label="Fecha de Inicio"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-2 my-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTabla" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTabla = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFecha()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-dialog
                    ref="dialogFechaTablaFin"
                    v-model="modalFechaTablaFin"
                    :return-value.sync="fechaTablaFin"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaFin"
                        label="Fecha de Fin"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-2 my-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaFin" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaFin = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaFin()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div>
                  <v-container fluid class="pa-0">
                    <v-layout>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <p class="subtitle-2">Cantidad de Ventas</p>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center">Tipo</th>
                                  <th class="text-center">Ventas Realizadas</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-center font-weight-bold">A Contado</td>
                                  <td class="text-center">{{computedContado}}</td>
                                </tr>
                                <tr>
                                  <td class="text-center font-weight-bold">A Crédito</td>
                                  <td class="text-center">{{computedCredito}}</td>
                                </tr>
                                <tr>
                                  <td class="text-center font-weight-bold">Total</td>
                                  <td class="text-center font-weight-bold">{{computedTotal}}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <p class="subtitle-2">Cantidad de Balones</p>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center">Tipo/Producto</th>
                                  <th class="text-center">10K</th>
                                  <th class="text-center">45K</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-center font-weight-bold">A Contado</td>
                                  <td class="text-center">{{countItemsGraphData('contado', '10K')}}</td>
                                  <td class="text-center">{{countItemsGraphData('contado', '45K')}}</td>
                                </tr>
                                <tr>
                                  <td class="text-center font-weight-bold">A Crédito</td>
                                  <td class="text-center">{{countItemsGraphData('credito', '10K')}}</td>
                                  <td class="text-center">{{countItemsGraphData('credito', '45K')}}</td>
                                </tr>
                                <tr>
                                  <td class="text-center font-weight-bold">Total</td>
                                  <td
                                    class="text-center font-weight-bold"
                                  >{{countItemsGraphData('total', '10K')}}</td>
                                  <td
                                    class="text-center font-weight-bold"
                                  >{{countItemsGraphData('total', '45K')}}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-container>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card :loading="isLoading">
              <v-card-title>Gráfico de Ventas</v-card-title>
              <v-card-text>
                <v-select
                  :items="selectGraphType"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  label="Tipo de Venta"
                  v-model="graphTypeModel"
                ></v-select>
                <area-chart
                  :data="auxGraphData[graphTypeModel]"
                  xtitle="Fecha"
                  ytitle="Cantidad"
                  label="Balones"
                  :messages="{empty: 'No hay información disponible'}"
                  legend="right"
                ></area-chart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-card>
              <v-data-table
                fixed-header
                :headers="headers"
                :items="ventas"
                :loading="isLoading"
                loading-text="Cargando... Espera un Momento"
                no-data-text="No hay datos para mostrar"
                :search="buscarTabla"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <div>
                      <v-toolbar-title>Lista de Ventas</v-toolbar-title>
                    </div>
                  </v-toolbar>
                  <v-layout class="py-3 d-flex">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="buscarTabla"
                          prepend-inner-icon="mdi-magnify"
                          label="Buscar en la tabla..."
                          class="mx-4 flex-shrink-0 flex-grow-1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-layout>
                </template>
                <template v-slot:item.fechaVenta="{ item }">{{ formatDateTable(item.fechaVenta) }}</template>
                <template
                  v-slot:item.costoVentaTotal="{ item }"
                >S/ {{ item.costoVentaTotal.toFixed(2) }}</template>
                <template v-slot:item.actions="{ item }">
                  <v-btn text icon color="blue">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn text icon color="red" @click="eliminarVentaDialog(item)">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: () => ({
    verificado: true,
    headers: [
      { text: '#', value: 'idVenta', align: 'center' },
      {
        text: 'Vendedor',
        value: 'nombreVendedor',
        align: 'center'
      },
      {
        text: 'Cliente',
        value: 'nombreCliente',
        align: 'center'
      },
      {
        text: 'Monto de Venta',
        value: 'costoVentaTotal',
        align: 'center'
      },
      { text: 'Fecha de Venta', value: 'fechaVenta', align: 'center' },
      {
        text: 'Tipo de Venta',
        value: 'tipo',
        align: 'center'
      },
      {
        text: 'Acciones',
        value: 'actions',
        sortable: false
      }
    ],
    ventas: [],
    fechaTabla: new Date(new Date().setHours(-5)).toISOString().substr(0, 10),
    fechaTablaFin: new Date(new Date().setHours(24))
      .toISOString()
      .substr(0, 10),
    modalFechaTabla: false,
    modalFechaTablaFin: false,
    buscarTabla: '',
    infoSnackbar: false,
    infoSnackbarMessage: '',
    showDialogEliminarVenta: false,
    eliminarVentaItem: {},
    graphVentas: {},
    selectGraphType: [
      { name: 'Todos', value: 'total' },
      { name: 'Contado', value: 'contado' },
      { name: 'Crédito', value: 'credito' }
    ],
    graphTypeModel: 'total',
    auxGraphData: { total: [], contado: [], credito: [] }
  }),
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      let horaCeroInicio = new Date(this.fechaTabla).setHours(24)
      let horaCeroFin = new Date(this.fechaTablaFin).setHours(48)
      //this.setError({ isError: false, error: "" });
      let fechas = { horaCeroInicio, horaCeroFin }
      this.auxGraphData = { total: [], contado: [], credito: [] }
      this.ventas = await this.fetchVentasTotalAdmin(fechas)
      this.graphVentas = await this.fetchVentasAgrupado(fechas)
      this.formatGraphData()
    },

    actualizarFecha() {
      this.$refs.dialogFechaTabla.save(this.fechaTabla)
      this.actualizarTabla()
    },

    actualizarFechaFin() {
      this.$refs.dialogFechaTablaFin.save(this.fechaTablaFin)
      this.actualizarTabla()
    },

    eliminarVentaDialog(item) {
      this.eliminarVentaItem = this.cleanSource(item)
      this.showDialogEliminarVenta = true
    },

    async eliminarVenta() {
      let response = await this.deleteVenta(this.eliminarVentaItem.idVenta)
      if (response) {
        this.showDialogEliminarVenta = false
        this.eliminarVentaItem = {}
        this.infoSnackbar = true
        this.infoSnackbarMessage =
          'Se ha eliminado ' + response.data.affectedRows + ' registro(s)'
        this.actualizarTabla()
      }
    },

    formatGraphData() {
      for (let j = 0; j < this.selectGraphType.length; j++) {
        let arrData = []
        if (!this.auxGraphData[this.selectGraphType[j].value].length) {
          arrData = this.graphVentas[this.graphTypeModel]
            ? this.cleanSource(this.graphVentas[this.selectGraphType[j].value])
            : []
          if (arrData.length) {
            for (let i = 0; i < arrData.length; i++) {
              // this.graphVentas[this.graphTypeModel][i].data = this.graphVentas[this.graphTypeModel][i].data
              // ? this.graphVentas[this.graphTypeModel][i].data.map(obj => Object.values(obj)) : []

              //Transforma objeto en array
              arrData[i].data = arrData[i].data
                .map(obj => Object.values(obj))
                .map(i => {
                  i.splice(i, 1)
                  return i
                })
            }
          }
        }

        this.auxGraphData[this.selectGraphType[j].value] = arrData
      }
    },

    countItemsGraphData(tipo, producto) {
      return this.auxGraphData[tipo].length
        ? this.auxGraphData[tipo]
            .filter(i => i.name == producto)[0]
            .data.reduce((ac, b) => ac + b[1], 0)
        : 0
    },

    ...mapActions('user', ['fetchVentasTotalAdmin']),

    ...mapActions('venta', ['deleteVenta', 'fetchVentasAgrupado']),

    ...mapMutations('user', ['setError', 'loadingRequest'])
  },
  computed: {
    computedFechaFormateada() {
      return this.formatDate(this.fechaTabla)
    },

    computedFechaFormateadaFin() {
      return this.formatDate(this.fechaTablaFin)
    },

    computedContado() {
      return this.ventas.reduce(
        (acc, cur) => (cur.tipo === 'Contado' ? ++acc : acc),
        0
      )
    },

    computedCredito() {
      return this.ventas.reduce(
        (acc, cur) => (cur.tipo === 'Credito' ? ++acc : acc),
        0
      )
    },

    computedTotal() {
      return this.ventas.length
    },

    computedGraphData() {
      return this.auxGraphData[this.graphTypeModel]
    },

    getFechaTabla() {
      const offsetDate = new Date(this.fechaTabla).setHours(
        new Date(this.fechaTabla).getHours() - 5
      )
      return new Date(offsetDate).toISOString().substr(0, 10)
    },

    getFechaFormateada() {
      return new Date(this.fechaTabla).toISOString().substr(0, 10)
    },

    getErrorMessage() {
      switch (this.getError.errorMessage) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.errorMessage
      }
    },

    ...mapGetters('user', ['isError', 'getError', 'isLoading'])
  },

  mounted: async function() {
    this.actualizarTabla()
  }
}
</script>

<style>
</style>